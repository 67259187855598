const INITIAL_STATE = {
    carteiras: {data: []},
    usuariosTotal: [],
  }
  
  export default (state = INITIAL_STATE, action) => {    
    switch (action.type) {
      case 'CARTEIRAS_FETCH':
        return { ...state, carteiras: action.payload }
      default:
        return state
    }
  }

