import { useState, useEffect, useContext } from "react"
import { useHistory } from "react-router-dom"
// import openSocket from "socket.io-client"

// import { toast } from "react-toastify"
// import toastError from "../../errors/toastError"
// import Toastify from '../../@core/components/toastify'

import api from "../../services/api"

const useAuth = () => {
  const history = useHistory()
  const [isAuth, setIsAuth] = useState(false)
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState({})

  api.interceptors.request.use(
    async config => {
      const token = localStorage.getItem("token")
      if (token) {
        config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`
        setIsAuth(true)
      }
      return config
    },
    error => {
      Promise.reject(error)
    }
  )

  api.interceptors.response.use(
    response => {
      return response
    },
    async error => {
      const originalRequest = error.config
      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true
        return api(originalRequest)
      }
      if (error?.response?.status === 401) {
        localStorage.removeItem("token")
        api.defaults.headers.Authorization = undefined
        setIsAuth(false)
      }
      return Promise.reject(error)
    }
  )

  useEffect(() => {
    const token = localStorage.getItem('token');
    
  }, [user])

  const handleLoginServer = async userData => {
    
    setLoading(true)
    try {

      const { data } = await api.post(`${process.env.REACT_APP_API_URL}login`)
      
      localStorage.setItem("token", JSON.stringify(data.token.token))
      localStorage.setItem("userData", JSON.stringify(data.usuario))

      api.defaults.headers.Authorization = `Bearer ${data.token.token}`
      
      setUser(userData.user)
      setIsAuth(true)
      setLoading(false)

    } catch (err) {
      setLoading(false)
      throw err
    }
  }

  const handleLogoutServer = async () => {
    console.log(localStorage.getItem("token"), "teste")
    setLoading(true)
    try {
      await api.delete("/logout")
      setIsAuth(false)
      setUser({})
      localStorage.removeItem("token")
      api.defaults.headers.Authorization = undefined
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return { isAuth, user, loading, handleLoginServer, handleLogoutServer }
}

export default useAuth
