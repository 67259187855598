const INITIAL_STATE = {
    pedidos: {data: []},
    usuariosTotal: [],
  }
  
  export default (state = INITIAL_STATE, action) => {    
    switch (action.type) {
      case 'PEDIDOS_FETCH':
        return { ...state, pedidos: action.payload }
      default:
        return state
    }
  }

