const INITIAL_STATE = {
    usuariosCliente: {
      data: []
    },
    usuariosTotal: [],
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'USUARIOS_CLIENTE_FETCH':
        return { ...state, usuariosCliente: action.payload }
      case 'USUARIOS_CLIENTE_TOTAL':
          return { ...state, usuariosTotal: action.payload }
      default:
        return state
    }
  }

