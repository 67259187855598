const INITIAL_STATE = {
    faturasSolicitacoes: {
      data: []
    },
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'FATURAS_SOLICITACOES_FETCH':
        return { ...state, faturasSolicitacoes: action.payload }
      default:
        return state
    }
  }

