const INITIAL_STATE = {
  solicitacoes: {
    data: []
  },
  statusParcelados: [],
  statusGlinPedido: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SOLICITACAO_ENVIO_FETCH':
      return { ...state, solicitacoes: action.payload }
    case 'SOLICITACAO_STATUS_PARCELA_FETCH':
      return { ...state, statusParcelados: action.payload }
    case 'SOLICITACAO_STATUS_GLIN_FETCH':
      return { ...state, statusGlinPedido: action.payload }
    default:
      return state
  }
}

