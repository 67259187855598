const INITIAL_STATE = {
    cashBacks: {
      data: []
    },
    cashBacksPagamento: {
      data: []
    },
    total: 0,
    totalDevido: 0
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'CASHBACK_FETCH':
        return { ...state, cashBacks: action.payload }
      case 'CASHBACK_PAGAMENTO_FETCH':
        return { ...state, cashBacksPagamento: action.payload }
      case 'CASHBACK_TOTAL':
        return { ...state, total: action.payload }
      case 'CASHBACK_TOTAL_DEVIDO':
        return { ...state, totalDevido: action.payload }
      default:
        return state
    }
  }