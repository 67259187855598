const INITIAL_STATE = {
    creditos: {
      data: []
    },
    usuariosDevendo: {
      data: []
    },
    totalCredito: 0,
    creditoDevida: 0,
    graficoDados: [],
    graficoDadosCreditos: [],
    media: 0,
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'CREDITOS_FETCH':
        return { ...state, creditos: action.payload }
      case 'CREDITOS_TOTAL':
        return { ...state, totalCredito: action.payload }
      case 'CREDITOS_DEVIDA':
        return { ...state, creditoDevida: action.payload }
      case 'CREDITOS_USUARIOS_FETCH':
        return { ...state, usuariosDevendo: action.payload }
      case 'CREDITOS_GRAFICO_FETCH':
        return { ...state, graficoDados: action.payload }
      case 'CREDITO_GRAFICO':
        return { ...state, graficoDadosCreditos: action.payload, media: action.media }
      default:
        return state
    }
  }

