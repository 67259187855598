const INITIAL_STATE = {
    usuariosPs: {
      data: []
    },
    usuariosTotal: [],
    psTopDez: [],
    usuarioPs: {}
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'USUARIOS_PS_SET':
        return { ...state, usuarioPs: action.payload }
      case 'USUARIOS_PS_FETCH':
        return { ...state, usuariosPs: action.payload }
      case 'USUARIOS_PS_TOTAL':
        return { ...state, usuariosTotal: action.payload }
      case 'PS_TOP_DEZ':
        return { ...state, psTopDez: action.payload }
      default:
        return state
    }
  }

