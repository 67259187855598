// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import usuarioPs from '../usuarioPs'
import usuarioCliente from '../usuarioCliente'
import credito from '../credito'
import faturaSolicitacao from '../faturaSolicitacao'
import cashBack from '../cashBack'
import pedido from '../pedido'
import carteiraSolicitacaoRecarga from '../carteiraSolicitacaoRecarga'
import solicitacaoEnvio from '../solicitacaoEnvio'

const appReducer = combineReducers({
    auth,
    navbar,
    layout,
    usuarioPs,
    usuarioCliente,
    credito,
    faturaSolicitacao,
    cashBack,
    pedido,
    carteiraSolicitacaoRecarga,
    solicitacaoEnvio
})

const rootReducer = (state, action) => {

    if (action.type === 'DESTROY_SESSION') state = undefined
    return appReducer(state, action);
}

export default rootReducer
